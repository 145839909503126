/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ActivityMode {
    Nothing = 0,
    FirstAccess = 1,
    Action = 2,
    Activity = 3,
    Duration = 4,
}
