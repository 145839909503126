/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ClientSegments {
    None = 0,
    EBooks = 1,
    AudioBooks = 2,
    Music = 4,
    Games = 8,
    Licences = 16,
    Movies = 32,
    Magazines = 64,
}
