/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PageType {
    Detail = 0,
    List = 1,
    CrossSell = 2,
    Recommended = 3,
    Order1 = 4,
    Default = 5,
    Article = 6,
    CommodityPack = 7,
    ActionCategory = 8,
    BackOffice = 9,
    ContactPage = 10,
    SearchPage = 11,
    Order2 = 12,
    Order3 = 13,
    Order4 = 14,
    Order5 = 15,
    DetailGenerated = 16,
    Complaints = 17,
    B2BSettings = 18,
    MobileBoMenu = 19,
    ComputerAssembly = 20,
    BenefitCode = 21,
    Unknown = -1,
}
