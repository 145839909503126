/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum SurveyObjectTypeEnum {
    None = 0,
    Article = 1,
    Commodity = 2,
    CategoryUpperDescription = 3,
    CategoryBottomDescription = 4,
    CategoryInfoCommodityGroup = 5,
}
