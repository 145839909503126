/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PaymentType {
    HomeCreditSK = 33,
    Cash = 48,
    OnDelivery = 49,
    BankOrderOnCredit = 50,
    Credit = 51,
    EBank = 52,
    Proforma = 53,
    AdyenApplePay = 54,
    PaymentCard = 55,
    AdyenPaymentCard = 56,
    LeasingOnline = 57,
    RogerPlus = 64,
    PayPal = 65,
    Order = 66,
    LeasingCardSlovakia = 67,
    LeasingCardCzechRegistration = 68,
    PayPalCE = 69,
    LeasingFirm = 70,
    GPWebPay = 71,
    TatraPay = 72,
    GPWebPayMasterPass = 73,
    CardPay = 74,
    LeasingCardCzech = 75,
    Leasing = 76,
    MyPayment = 77,
    GiroPay = 78,
    CsobSk = 79,
    Payment24 = 80,
    QRCode = 81,
    SporoPay = 82,
    LeasingSlovakia = 83,
    Terminal = 84,
    PaymentCardInvoice = 85,
    EPlatbyVUB = 86,
    AlzaWallet = 87,
    Sofort = 88,
    CsobPaymentButton = 89,
    MPlatba = 90,
    HomeCreditCZ = 94,
    AdyenKlarnaPayNow = 179,
    BraintreePayPal = 193,
    BraintreePayPalExpress = 201,
    BitcoinPayLitecoin = 205,
    BitcoinPay = 211,
    AdyenKlarna = 218,
    BraintreeCard = 221,
    EpsOnlinePayment = 268,
    SepaDirectDebit = 270,
    PayPalCheckout = 282,
    IdealOnlinePayment = 327,
    AdyenGooglePay = 344,
    LeasingHungary = 352,
    CsobCz = 356,
    CsobCard = 381,
}
