/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum HistoryActionEnum {
    None = 0,
    Insert = 1,
    Edit = 2,
    Delete = 3,
    Login = 4,
    Logout = 5,
    Confirm = 6,
}
