/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ObjectLockType {
    ConfigurationSection = 1,
    Article = 2,
    CategoryText = 3,
    CategoryProducerText = 4,
    MailListAction = 5,
}
