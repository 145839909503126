/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum DeliveryBranchExpeditionAbility {
    None = 0,
    Local = 1,
    Country = 2,
    Global = 3,
    Central = 4,
    Dropshipment = 5,
}
