/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AccesoryPriceCalculation {
    Direct = 2,
    Percent = 3,
    Commodity = 4,
    Bound = 5,
    PercentWithoutVat = 6,
    Extra40CZK = 7,
}
