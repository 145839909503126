/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AccessoryLabelType {
    NoLabel = 0,
    Tip = 1,
    Free = 2,
    News = 3,
    Action = 4,
}
