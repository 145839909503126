/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum MailingChangeType {
    Undefined = 0,
    User = 1,
    MailPump = 2,
    Import = 3,
    UserProfile = 10,
    Order = 11,
    SendNews = 12,
    Registration = 13,
    Gdpr = 18,
}
