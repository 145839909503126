/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AccessoryGroupType {
    Transport = 0,
    ExtendedWaranty = 1,
    ImmediateReplacement = 2,
    InsurenceBreakAndTheft = 3,
    AntiVirusAndSecurity = 4,
    FirstRun = 7,
    BuyBack = 15,
    GoodsReturn = 23,
    Ppl = 24,
    Rheus = 25,
    SkinZone = 26,
    InsurenceTheft = 29,
    Geis = 31,
    InappropriateGift = 37,
    AlzaExpres = 42,
    TopTrans = 50,
    Helicar = 51,
    Logflex = 52,
    AlzaExpresLight = 56,
    RegionalPartnerCarrier = 57,
    GlassProtectionApplication = 59,
}
