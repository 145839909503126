/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum OrderDelayType {
    NoDelay = 0,
    TwoOrThreeDays = 3,
    FiveOrSevenDays = 5,
    OneDay = 6,
    TwoDays = 7,
    ThreeDays = 8,
    FourDays = 9,
    FiveDays = 10,
    SixDays = 11,
    SevenDays = 12,
}
