/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AnonymousRegistrationType {
    None = 0,
    Simple = 10,
    RegisteredSimple = 15,
    Full = 20,
    Registered = 30,
}
